<template>
    <div>
      <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">

        <!-- <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-form-input id="nameFilter" v-model="nameFilter"
            placeholder="Search by room name" />
        </div> -->

        <div v-if="!messSecretary && !messReceptionist" 
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-form-input id="messNameFilter" v-model="messNameFilter"
            placeholder="Search by mess name" />
        </div>
        <div v-if="!messSecretary && !messReceptionist" 
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-form-input id="cityNameFilter" v-model="cityNameFilter"
            placeholder="Search by city name" />
        </div>


          <b-form-group label-for="roomTypes">
            <v-select
              inputId="id"
              label="name"
              v-model="selectedRoomType"
              :options="roomTypes"
              autoscroll
              :clearable="true"
              class="bg-white mt-1"
              style="border-radius: 0.357rem; width: 190px;"
              placeholder="Select Room Type"
            ></v-select>
          </b-form-group>
          <b-form-group label="Availability" class="ml-5">
            <b-form-radio-group
              id="availability"
              v-model="selectedAvailabilityOption"
              :options="availabilityOptions"
              name="availability"
            ></b-form-radio-group>
          </b-form-group>
        <!-- <div class="d-flex justify-content-center align-items-center flex-nowrap mr-5">
          <span class="mr-50">Room Type </span>
          <v-select inputId="id" label="name" v-model="selectedRoomType" :options="roomTypes" autoscroll :clearable="true"
            class="px-3 bg-white"  aria-placeholder="Select Room Type"></v-select>
        </div>  -->
      </div>
      <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-button variant="primary" pill @click="filter">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </div>
  
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="RefreshCwIcon" class="mr-50" />
            <span class="align-middle">Refresh</span>
          </b-button>
        </div>
  
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
                <b-button variant="primary" pill @click="createRoom"
                  v-if="hasPermission('room_create')">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="align-middle">Create</span>
                </b-button>
        </div>
  
        <div class="text-right">
          <b-button variant="primary" pill @click="createMutlipleRooms"
            v-if="hasPermission('room_create')">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Create Multiple Rooms</span>
          </b-button>
        </div>
        <!-- <div class="text-right ml-2">
          <b-button variant="info" pill @click="downloadRoomsReport">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Download Available Rooms</span>
          </b-button>
        </div> -->
      </div>   
      <b-card>
        <b-table responsive :fields="fields" :items="rooms"  details-td-class="m-0 p-0" small
          v-if="hasPermission('room_show')" :per-page="0" :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(is_unavailable)="row">
          <template v-if="!row.item.is_unavailable">
            <feather-icon icon="CheckIcon" class="mr-50" />
          </template>
          <template v-else>
            <feather-icon icon="XIcon" class="mr-50" />
          </template>
        </template>
          <template #cell(created_by_data)="row">
            {{ row.item.created_by_data ? row.item.created_by_data.user : "" }}
          </template>
          <template #cell(updated_by_data)="row">
            {{ row.item.updated_by_data ? row.item.updated_by_data.user : "" }}
          </template>
          <template #cell(manage)="row">
          <div class="d-flex align-items-center">
            <b-button variant="info" pill size="sm" class="mr-1" @click="viewRoom(row.item)"
              v-if="hasPermission('room_read')">
              View
            </b-button>
            <template>
              <b-button variant="info" pill size="sm" class="mr-1" @click="editRoom(row.item)"
                v-if="hasPermission('room_update')">
                Edit
              </b-button>
            </template>
            <template v-if="!row.item.is_unavailable">
              <b-button variant="warning" pill size="sm" class="text-truncate mr-1" @click="makeUnavailableConfirm(row.item)"
                v-if="hasPermission('room_update')">
                Make unavailable
              </b-button>
            </template>
            <template v-if="row.item.is_unavailable">
              <b-button variant="success" pill size="sm" class="text-truncate mr-1" @click="makeAvailable(row.item)"
                v-if="hasPermission('room_update')">
                Make available
              </b-button>
            </template>
            <template v-if="row.item.bookings_count == 0">
              <b-button variant="danger" pill size="sm" class="mr-1" @click="deleteRoom(row.item)"
                v-if="hasPermission('room_delete')">
                Delete
              </b-button>
            </template>
            <!-- <template v-if="row.item.feedback_qr_code"> -->
              <b-button variant="success" pill size="sm" class="mr-1 text-truncate" @click="showQr(row.item.feedback_qr_code)"
                v-if="hasPermission('room_read')">
                Show QR Code
                <!-- {{ row.item.feedback_qr_code }} -->
              </b-button>
            </div>
            <!-- </template> -->
          </template>
        </b-table>
        <b-row>
                <b-col md="2">
                    <div style="float:left">
                        <h5 style="margin: 0; display: inline;" class="text-primary">Count: </h5>
                        <h5 style="margin: 0; display: inline;" align="right">
                            <strong>
                                {{totalDataLength}}
                            </strong>
                        </h5>
                    </div>
                </b-col>
            </b-row>
  
        <div class="d-flex justify-content-center align-items-center flex-nowrap m-2">
          <b-pagination size="md" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"></b-pagination>
        </div>
      </b-card>
      <room-create-modal :room="room" :data="data" @modalClosed="onModalClosed"
        :showModal="visible" :key="`create-${roomCreateModalCount}`" />
      <RoomCreateModalMultiple :room="room" :data="data" @modalClosed="onModalClosed"
        :showModal="visible" :key="`create-multiple-${roomMultipleCreateModalCount}`" />
      <room-edit-modal :room="room" :data="data" :allowEdit="allowEdit" @modalClosed="onModalClosed"
        :showModal="visible" :key="`edit-${roomEditModalCount}`" />
     <qr-show-modal @modalClosed="onModalClosed" 
    :showModal="visible" :url="url" :key="`view-${QrShowModalCount}`" />
    <!-- <b-modal
      id="room-unavailable-modal"
      title="Make Room Unavailable"
      @modalClosed="onModalClosed"
      @hidden="makeUnavailableHidden"
      :showModal="visible"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      hide-footer
      :key="`unavailable-${roomUnavailableModalCount}`"
      centered
    >
    <validation-observer ref="roomUnavailableModalValidation">
          <b-form @submit.prevent>
            <b-row>
              <b-col>
                  <b-form-checkbox
                    id="makeUnavailableNow"
                    v-model="makeUnavailableNow"
                    name="makeUnavailableNow"
                  >
                    Make Unavailable Now
                  </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" sm="6" lg="6" xl="6">
                  <b-form-group label-for="unavailableFrom">
                    <template #label
                      ><span class="text-white font-weight-bolder">
                        From Date
                      </span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="unavailableFromField"
                      :rules="{ required: true }"
                    >
                      <b-input-group>
                        <b-form-input
                          id="unavailableFrom"
                          v-model="unavailableFrom"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="on"
                          disabled
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            id="unavailableFromDatePicker"
                            v-model="unavailableFrom"
                            button-variant="primary"
                            :min="minDate"
                            button-only
                            right
                            variant="primary"
                            :disabled="unavailableFromDisabled"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
              <b-col md="6" sm="6" lg="6" xl="6">
                <b-form-group label-for="unavailableTo">
                    <template #label
                      ><span class="text-white font-weight-bolder">
                        To Date
                      </span></template
                    >
                    <validation-provider
                      #default="{ errors }"
                      name="unavailableToField"
                      :rules="{ required: true }"
                    >
                      <b-input-group>
                        <b-form-input
                          id="unavailableTo"
                          v-model="unavailableTo"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="on"
                          disabled
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            id="unavailableToDatePicker"
                            v-model="unavailableTo"
                            button-variant="primary"
                            :min="minDate"
                            button-only
                            right
                            variant="primary"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button variant="primary" size="sm" class="mr-1 ml-auto mt-1 p-75 px-2" @click="makeUnavailableConfirm()">
                Confirm
              </b-button>              
            </b-row>
          </b-form>
          </validation-observer>
    </b-modal> -->
        <b-overlay :show="show" spinner-variant="primary" no-wrap />
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import util from "@/util.js";
  import RoomCreateModal from "@/components/booking/admin/RoomCreateModal.vue";
  import RoomCreateModalMultiple from "@/components/booking/admin/RoomCreateModalMultiple.vue";
  import RoomEditModal from "@/components/booking/admin/RoomEditModal.vue";
  import QrShowModal from "@/components/booking/admin/QrShowModal.vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import moment from "moment";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      RoomCreateModal,
      RoomCreateModalMultiple,
      RoomEditModal,
      QrShowModal,
    },
    
    data() {
      return {
        fields: [
          { key: "manage", label: "Manage" },
        //   { key: "id", label: "Room ID" },
          { key: "name", label: "Room Name" },
          { key: "mess_data.name", label: "Mess Name" },
          // { key: "floor", label: "Floor" },
          { key: "room_type_data.name", label: "Room Type" },
          { key: "starting_lien_name", label: "Lien" },
          { key: "starting_category_name", label: "Category" },
          { key: "is_unavailable", label: "Available for booking" },
        //  { key: "is_unavailable_from", label: "Unavailable From" },
         // { key: "is_unavailable_to", label: "Unavailable To" },
          { key: "last_booking_checkout", label: "Last Booking Checkout" },
          { key: "created_at", label: "Created At" },
          { key: "created_by_data", label: "Created By" },
          { key: "updated_at", label: "Last updated" },
          { key: "updated_by_data", label: "Updated By" },
        ],
        currentPage: 1,
        perPage: 0,
        totalItems: 0,
        room: null,
        rooms: [],
        roomCreateModalCount: 0,
        roomMultipleCreateModalCount: 0,
        roomEditModalCount: 0,
        QrShowModalCount: 0,
        visible: false,
        nameFilter: "",
        messNameFilter: "",
        cityNameFilter: "",
        roomTypes: [],
        selectedRoomType: null,
        // floorFilter: "",
        isBusy: false,
        data: {},
        allowEdit: true,
        url: "",
        totalDataLength: 0,
        // unavailableFrom: null,
        // unavailableTo: null,
        // unavailableFromDisabled: true,
        makeUnavailableNow: true,
        roomUnavailableModalCount: 0,
        messSecretary: false,
        messReceptionist: false,
        minDateObj: null,
        minDate: null,
        selectedAvailabilityOption: null,
        availabilityOptions: [
          { text: 'All', value: null },
          { text: 'Available', value: false},
          { text: 'Unavailable', value: true},
        ],
        show: false,
        systemTime: null,
      };
    },
        mixins: [util],
    async mounted() {
      let currentDate = await this.getCurrentDate()
      this.minDateObj = new Date(currentDate.getTime())
      this.minDate = await this.getPakistanDateString(currentDate);
      this.systemTime = currentDate.toLocaleString("en-US", {timeZone: "Asia/Karachi"}).split(",")[1].split(" ")[1]
      this.makeUnavailableNow = true
      this.unavailableFrom = this.minDate
      let res = await this.getRoomFormData()
      this.data = res.data
      this.roomTypes = res.data.room_types
      if(this.getLoggedInUser.mess_secretary){
        this.messSecretary = true
        this.selectedMessId = this.getLoggedInUser.mess_secretary.mess
      }
      else if(this.getLoggedInUser.clerk){
        this.messReceptionist = true
        this.selectedMessId = this.getLoggedInUser.clerk.mess
      }
      // await this.search();
    },
    methods: {
      ...mapActions({
        getRooms: "appData/getRooms",
        updateRoom: "appData/updateRoom",
        delete: "appData/deleteRoom",
        getRoomTypes: "appData/getRoomTypes",
        getRoomFormData: "appData/getRoomFormData",
        getMessesUnpaginated: "appData/getMessesUnpaginated",
        // updateRoomAttribute: "appData/updateRoomAttribute"
        fetchSystemTime: "appData/fetchSystemTime",
      }),
      async getCurrentDate() {
        await this.fetchSystemTime()
        var systemDateTime  = await this.getSystemTime;
        var currentDate = new Date(systemDateTime);
        return currentDate
      },
      async getPakistanDateString(date){
        date = date.toLocaleString("en-US", {timeZone: "Asia/Karachi"}).split(",")[0];
        var day = date.split("/")[1]
        var month = date.split("/")[0]
        var year = date.split("/")[2]
        return year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
      },
      async filter() {
        this.currentPage = 1;
        await this.search();
      },
      async search() {
        this.isBusy = true;
        await this.fetchPaginatedData();
        this.isBusy = false;
      },
      createRoom() {
          this.roomCreateModalCount += 1;
          this.visible = true;
          this.$nextTick(() => {
            this.$bvModal.show("room-create-modal");
          });
      },
      createMutlipleRooms() {
          this.roomMultipleCreateModalCount += 1;
          this.visible = true;
          this.$nextTick(() => {
            this.$bvModal.show("room-multiple-create-modal");
          });
      },
      editRoom(room) {
        this.room = room;
        this.roomEditModalCount += 1;
        this.visible = true;
        this.allowEdit = true;
        this.$nextTick(() => {
          this.$bvModal.show("room-edit-modal");
        });
      },
      viewRoom(room) {
        this.room = room;
        this.roomEditModalCount += 1;
        this.visible = true;
        this.allowEdit = false;
        this.$nextTick(() => {
            this.$bvModal.show("room-edit-modal");
        });
      },
      //HARD DELETE
    //   async deleteRoom(room) {
    //   try {
    //     this.$swal({
    //       title:
    //         "Are you sure?",
    //       icon: "warning",
    //       showCloseButton: true,
    //       showCancelButton: true,
    //       confirmButtonText: "Confirm",
    //       customClass: {
    //         confirmButton: "btn btn-primary",
    //         cancelButton: "btn btn-danger ml-1",
    //       },
    //       buttonsStyling: false,
    //     }).then(async (result) => {
    //       if (result.value) {
    //         try {
    //           const res = await this.delete({
    //             pk: room.id,
    //           });
    //           if (res.status === 204) {
    //             this.$swal({
    //               title: "Room deleted successfully",
    //               icon: "success",
    //             });
    //             this.$nextTick(() => {
    //               this.search();
    //             });
    //             this.$emit("modalClosed");
    //           }
    //         } catch (error) {
    //           this.show = false;
    //           if (error.response.status == 500) {
    //             // this.displayError("Kindly make sure this mess does not have any dependencies");
    //             this.$swal({
    //               title: "Kindly make sure this room does not have any dependencies",
    //               icon: "error",
    //             });
    //           }
    //           else {
    //             this.displayError(error);
    //           }
    //         }
    //       } else {
    //         this.show = false;
    //       }
    //     });
    //   } catch (error) {
    //     this.displayError(error);
    //   }
    // },


      // SOFT DELETE
      deleteRoom(room) {
        try {
          this.$swal({
            title:
              "Are you sure?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              try {
                const res = await this.updateRoom({
                  payload: {
                    is_delete: true,
                    updated_by: this.getLoggedInUser.id,
                  },
                  pk: room.id,
                });
                let response_string = "Room deleted successfully";
                let response_msg = response_string;
                if (res.data.response_msg) {
                  response_msg = response_msg.concat(res.data.response_msg);
                }
                if (res.status === 200) {
                  this.$swal({
                    title: response_msg,
                    icon: "success",
                  });
                  this.$nextTick(() => {
                    this.fetchPaginatedData();
                  });
                  this.$emit("modalClosed");
                }
              } catch (error) {
                this.show = false;
                this.displayError(error);
              }
            } else {
              this.show = false;
            }
          });
        } catch (error) {
          this.displayError(error);
        }
      },
      async makeUnavailableConfirm(){
        try {
          let payload = {
            is_unavailable_from: this.unavailableFrom + ' 00:00:00',
            is_unavailable_to: this.unavailableTo + ' 00:00:00',
            updated_by: this.getLoggedInUser.id,
          }
          if(this.makeUnavailableNow){
            payload['is_unavailable'] = true
            payload['is_unavailable_from'] = this.unavailableFrom + ' ' + this.systemTime
          }
          const res = await this.updateRoom({
            payload: payload,
            pk: this.room.id,
          });
          let response_string = "Room is now unavailable";
          let response_msg = response_string;
          if (res.data.response_msg) {
            response_msg = response_msg.concat(res.data.response_msg);
          }
          if (res.status === 200) {
            this.$swal({
              title: response_msg,
              icon: "success",
            });
            this.$nextTick(() => {
              this.fetchPaginatedData();
            });
            this.$bvModal.hide("room-unavailable-modal");
          }

        } catch (error) {
          this.displayError(error);
        }
      },
     // makeUnavailable(room){
       // this.room = room
       // this.roomUnavailableModalCount += 1;
      //  this.visible = true;
       // this.$nextTick(() => {
      //      this.$bvModal.show("room-unavailable-modal");
      //  });
     // },
     // makeUnavailableHidden(){
     //   this.unavailableFrom= this.minDate;
     //   this.unavailableTo= null
    //    this.unavailableFromDisabled= true
    //    this.makeUnavailableNow= true
    //  },
      makeAvailable(room){
        try {
          this.$swal({
            title:
              "Are you sure?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              try {
                const res = await this.updateRoom({
                  payload: {
                    is_unavailable: false,
                    is_unavailable_from: null,
                    is_unavailable_to: null,
                    updated_by: this.getLoggedInUser.id,
                  },
                  pk: room.id,
                });
                let response_string = "Room is now available";
                let response_msg = response_string;
                if (res.data.response_msg) {
                  response_msg = response_msg.concat(res.data.response_msg);
                }
                if (res.status === 200) {
                  this.$swal({
                    title: response_msg,
                    icon: "success",
                  });
                  this.$nextTick(() => {
                    this.fetchPaginatedData();
                  });
                  this.$emit("modalClosed");
                }
              } catch (error) {
                this.show = false;
                this.displayError(error);
              }
            } else {
              this.show = false;
            }
          });
        } catch (error) {
          this.displayError(error);
        }
      },
     async makeUnavailableConfirm(room){
        try {
          this.$swal({
            title:
              "Are you sure?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              try {
                const res = await this.updateRoom({
                  payload: {
                    is_unavailable: true,
                   // is_unavailable_from: null,
                   // is_unavailable_to: null,
                    updated_by: this.getLoggedInUser.id,
                  },
                  pk: room.id,
                });
                let response_string = "Room is now Unavailable";
                let response_msg = response_string;
                if (res.data.response_msg) {
                  response_msg = response_msg.concat(res.data.response_msg);
                }
                if (res.status === 200) {
                  this.$swal({
                    title: response_msg,
                    icon: "success",
                  });
                  this.$nextTick(() => {
                    this.fetchPaginatedData();
                  });
                  this.$emit("modalClosed");
                }
              } catch (error) {
                this.show = false;
                this.displayError(error);
              }
            } else {
              this.show = false;
            }
          });
        } catch (error) {
          this.displayError(error);
        }
      },
      async onModalClosed() {
        await this.fetchPaginatedData();
        this.visible = false;
      },
      async fetchPaginatedData() {
        try {
          let tempParams = {
            pageNumber: this.currentPage,
          };
          if(this.getLoggedInUser.mess_secretary){
            tempParams["mess"] = this.getLoggedInUser.mess_secretary.mess;
          }
          else if(this.getLoggedInUser.clerk){
            tempParams["mess"] = this.getLoggedInUser.clerk.mess;
          }
          if (this.nameFilter) {
            tempParams["name"] = this.nameFilter;
          }
          if (this.messNameFilter) {
            tempParams["mess_name"] = this.messNameFilter;
          }
          if (this.cityNameFilter) {
            tempParams["city_name"] = this.cityNameFilter;
          }
          // if (this.floorFilter) {
          //   tempParams["floor"] = this.floorFilter;
          // }
          if (this.selectedRoomType) {
            tempParams["room_type"] = this.selectedRoomType.id;
          }
          if (this.selectedLien) {
            tempParams["lien_name"] = this.selectedLien.id;
          }
          if (this.selectedCategory) {
            tempParams["category_name"] = this.selectedCategory.id;
          }
          if (this.selectedAvailabilityOption !== null && typeof this.selectedAvailabilityOption !== "undefined") {
            tempParams["is_unavailable"] = this.selectedAvailabilityOption;
          }
          const res = await this.getRooms(tempParams);
          this.rooms = res.data.results;
          
          this.totalItems = res.data.count;
          this.perPage = res.data.per_page;
          this.totalDataLength = res.data.count;
        } catch (error) {
          this.displayError(error);
        }
      },
      showQr(item){
        this.url = item
        this.QrShowModalCount += 1;
        this.visible = true;
        this.$nextTick(() => {
            this.$bvModal.show("qr-show-modal");
        });
      },
    },
    computed: {
      ...mapGetters({
        hasPermission: "appData/hasPermission",
        getLoggedInUser: "appData/getUser",
        getSystemTime: "appData/getSystemTime",
      }),
    },
    watch: {
        // async makeUnavailableNow(newValue, oldValue) {
        //   if (newValue) {
        //     let currentDate = await this.getCurrentDate()
        //     this.minDateObj = new Date(currentDate.getTime())
        //     this.minDate = await this.getPakistanDateString(currentDate);
        //     this.systemTime = currentDate.toLocaleString("en-US", {timeZone: "Asia/Karachi"}).split(",")[1].split(" ")[1]
        //     this.unavailableFrom = this.minDate;
        //     this.unavailableFromDisabled = true
        //   }
        //   else{
        //     let currentDate = await this.getCurrentDate()
        //     let minDateObj = new Date(currentDate.getTime())
        //     minDateObj = new Date(minDateObj.setDate(minDateObj.getDate() + 1));
        //     this.minDateObj = minDateObj
        //     this.minDate = await this.getPakistanDateString(this.minDateObj);
        //     this.systemTime = currentDate.toLocaleString("en-US", {timeZone: "Asia/Karachi"}).split(",")[1].split(" ")[1]
        //     this.unavailableFrom = this.minDate;
        //     this.unavailableFromDisabled = false
        //   }
        // },      
      currentPage: async function (val) {
        await this.fetchPaginatedData();
      },
    },
  };
  </script>
  
  <style>
  .v-select input::placeholder {
    color: rgb(184, 180, 197);
  }
  
  </style>