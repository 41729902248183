

<template>
    <b-modal id="room-multiple-create-modal" title="Create Rooms" centered hide-footer scrollable size="lg"
      @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" dialog-class="my-dialog-class">
      <template #modal-title>
        <h2 class="m-0">Create Room</h2>
      </template>
      <validation-observer ref="roomCreateFormValidation">
        <b-form @submit.prevent>
          <b-row>
            <!-- <b-col md="6">
              <b-form-group label="Name" label-for="name">
                <template #label>
                  Room Name <span class="text-danger">*</span>
                </template>
                  <validation-provider #default="{ errors }" name="Name"
                      :rules="{ required, regex: /^[0-9A-Z-()#&''\s]*$/i }">
                      <b-form-input id="name" v-model="name" :state="errors.length > 0 ? false : null"
                      placeholder="Name" />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col md="6">
                <b-form-group label="Count" label-for="count">
                <template #label>
                    Number of rooms <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Count"
                    :rules="{ required, regex: /^([0-9]){1,3}$/i }">
                    <b-form-input id="count" v-model="count"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Number of rooms to create" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
            </b-col>    
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Mess" rules="required">
              <b-form-group label-for="mess" :state="errors.length > 0 ? false : null">
                  <template #label>
                  Mess <span class="text-danger">*</span>
                  </template>
                  <b-row>
                      <b-col>
                          <v-select id="mess" inputId="id" label="name" v-model="selectedMess"
                          :options="messes" placeholder="Mess" class="v-style-chooser" />
                      </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
             <b-col md="6">
              <validation-provider #default="{ errors }" name="Categories" rules="required">
              <b-form-group label-for="categories" :state="errors.length > 0 ? false : null">
                  <template #label>
                  Category <span class="text-danger">*</span>
                  </template>
                  <b-row>
                      <b-col>
                          <v-select id="categories" inputId="id" label="name" v-model="selectedCategory"
                          :options="categories" placeholder="Categories" class="v-style-chooser"/>
                      </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="RoomType" rules="required">
              <b-form-group label-for="roomType" :state="errors.length > 0 ? false : null">
                  <template #label>
                  Room Type <span class="text-danger">*</span>
                  </template>
                  <b-row>
                      <b-col>
                          <v-select id="roomType" inputId="id" label="name" v-model="selectedRoomType"
                          :options="roomTypes" placeholder="Room Type" class="v-style-chooser"/>
                      </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Liens" rules="required">
              <b-form-group label-for="liens" :state="errors.length > 0 ? false : null">
                  <template #label>
                  Lien <span class="text-danger">*</span>
                  </template>
                  <b-row>
                      <b-col>
                          <v-select id="liens" inputId="id" label="name" v-model="selectedLien"
                          :options="liens" placeholder="Liens" class="v-style-chooser"/>
                      </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col md="12">
                <b-form-group label="Count" label-for="count">
                <template #label>
                    Number of rooms <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Count"
                    :rules="{ required, regex: /^([0-9]){1,3}$/i }">
                    <b-form-input id="count" v-model="count"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Number of rooms to create" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
            </b-col>                
          </b-row> -->
           <b-form-group class="text-right">
            <b-button type="submit" variant="primary" :disabled="!isSelectionValid" pill class="mr-1 mt-2" @click="validationForm">
              Create
            </b-button>
          </b-form-group>
        </b-form>
      </validation-observer>
      <h4 class="text-center text-danger mt-1"><strong>Room can either have a lien or a category. Please select one</strong></h4>
    </b-modal>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import draggable from "vuedraggable";
  import util from "@/util.js";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VuePerfectScrollbar,
      draggable,
    },
    mixins: [util],
    props: ["showModal","data"],
    data() {
      return {
        perfectScrollbarSettings: {
          maxScrollbarLength: 150,
          wheelPropagation: false,
          useBothWheelAxes: false,
          suppressScrollX: true,
        },
        name: "",
        selectedMess: null,
        messes: [],
        selectedLien: null,
        liens: [],
        selectedCategory: null,
        categories: [],
        // floor: 0,
        selectedRoomType: null,
        roomTypes: [],
        required,
        count: 0,
      };
    },
    async mounted() {
      if (this.showModal) {
        try {
            this.liens = this.data.liens
            this.categories = this.data.categories
            this.messes = this.data.messes
            this.roomTypes = this.data.room_types
        } catch (error) {
          this.displayError(error);
        }
      }
    },
    methods: {
      ...mapActions({
        createRoom: "appData/createRoom",
      }),
      async validationForm() {
        const success = await this.$refs.roomCreateFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
      async submit() {
        try {
          let formData = new FormData();
  
          let dataToInsert = {
            name: this.name,
            mess: this.selectedMess.id,
            room_type: this.selectedRoomType.id,
            starting_lien: this.selectedLien.id,
            starting_category: this.selectedCategory.id,
            // floor: this.floor,
            rooms_to_create: this.count,
            room_type: this.selectedRoomType.id,
            created_by: this.getLoggedInUser.id,
            updated_by: this.getLoggedInUser.id,
          };
          formData.append("data", JSON.stringify(dataToInsert));

          const res = await this.createRoom(formData);
          if (res.status === 201) {
            this.$swal({
              title: "Room created successfully",
              icon: "success",
            });
            // await this.resetModal();
            this.$nextTick(() => {
              this.$bvModal.hide("room-multiple-create-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
      reset() { },
      resetModal() {
      },
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getUser" }),
      isSelectionValid() {
        const isCategoryNone = this.selectedCategory && this.selectedCategory.name === 'None';
        const isLienNone = this.selectedLien && this.selectedLien.name === 'None';
        const bothSelectedAndNotNone = !isCategoryNone && !isLienNone;
        return !bothSelectedAndNotNone;
      },
    },
    watch: {
    },  
  };
  </script>
  
  <style lang="scss" scoped>
  .scroll-area-size {
    height: 45vh;
  }
  
  .scroll-area-size ul {
    height: 100%;
  }
  </style>
  